import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import Link from 'next/link';
import Moment from 'react-moment';
import ProjectStatusLabel from './project-status-label';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectRole from '../../domain/models/project-role';
import Client from '../../domain/models/client';

const Container = styled.div`
  background-color: ${theme.colors.background};
  border-radius: 0.2rem;
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  box-shadow: 0 1px 3px rgb(2 4 69 / 4%);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
`;
const TitleContainer = styled.div`
  width: 40%;
  h3 {
    color: ${theme.colors.text};
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    margin-right: 1rem;
  }
  font-size: 13px;
  font-weight: 400;
`;
const ProjectContainer = styled.div`
  width: 35%;
  h4 {
    color: ${theme.colors.text};
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-right: 1rem;
  }
  h5 {
    color: ${theme.colors.text};
    font-size: 14px;
    font-weight: 300;
    margin: 0;
    margin-right: 1rem;
  }
`;
const StatsContainer = styled.div`
  width: 25%;
  text-align: right;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  svg {
    margin-right: 0.25rem;
  }
  .spacer {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  h6 {
    font-size: 14px;
    font-weight: 300;
    margin: 0;
  }
`;
const ItemLink = styled.a`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &:after {
    content: ' ';
  }
`;
const List = styled.ul`
  margin: 0;
  margin-top: 0.2rem;
  padding: 0;
  font-size: 13px;
  font-weight: 300;
`;
const ListItem = styled.li`
  list-style-type: none;
`;
const ListItemSubtitle = styled.span``;

type PropsType = {
  projectRole: ProjectRole;
  clientId?: number;
};

function ProjectRoleRow(props: PropsType) {
  const { projectRole, clientId } = props;

  const linkForProjectRole = () => {
    if (clientId) {
      let invitation = null;
      if (projectRole.invitations) {
        invitation = projectRole.invitations.find((i) => i.client_id == clientId);
      }
      if (invitation) {
        return `/invitations/client/${invitation.unique}`;
      }
    }
    return `/`;
  };

  return (
    <Container>
      <TitleContainer>
        <h3>{projectRole.title}</h3>
      </TitleContainer>
      <ProjectContainer>
        {projectRole.project && (
          <>
            <h4>{projectRole.project.title}</h4>
            {projectRole.project.buyer?.title && <h5>{projectRole.project.buyer?.title}</h5>}
          </>
        )}
      </ProjectContainer>
      <StatsContainer>
        {!clientId && (
          <h6>
            {projectRole.total_submissions_pending == 1
              ? `1 Pending`
              : `${projectRole.total_submissions_pending || 0} Pending`}
          </h6>
        )}
        {projectRole.project && projectRole.project.due && (
          <h6>
            <FontAwesomeIcon icon={faClock} />
            <Moment format="D MMM">{projectRole.project.due}</Moment>
          </h6>
        )}
      </StatsContainer>
      {clientId ? (
        <ItemLink href={linkForProjectRole()} target="_blank" />
      ) : (
        <Link href={`/project-roles/${projectRole.id}`}>
          <ItemLink />
        </Link>
      )}
    </Container>
  );
}

export default ProjectRoleRow;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import theme from "../styles/theme";
import Link from "next/link";
import { signIn } from "next-auth/client";

const MainContainer = styled.div``;
const BackgroundImage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -1;
  background-color: #999999;
  background-size: cover;
`;
const CTA = styled.div`
  padding: 2rem 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  h1 {
    color: #ffffff;
    margin-top: 0;
  }
  button {
    outline: none;
    background-color: ${theme.colors.primary};
    border: none;
    border-radius: 0.2rem;
    color: #ffffff;
    padding: 0.5rem 1rem;
  }
`;

function Login(props) {
  const { config } = props
  const [backgroundImageUrl, setBackgroundImageUrl] = useState<string>(null);

  useEffect(() => {
    if (config?.login_background?.url) {
      setBackgroundImageUrl(config.login_background.url);
    }
  }, [config]);

  return (
    <MainContainer>
      {backgroundImageUrl && (
        <BackgroundImage
          style={{ backgroundImage: `url('${backgroundImageUrl}'` }}
        />
      )}
      <CTA>
        <h1>Virtual VOX</h1>
        <Link href="/api/auth/signin">
          <button
            onClick={(e) => {
              e.preventDefault();
              signIn("cognito");
            }}
          >
            Sign In
          </button>
        </Link>
      </CTA>
    </MainContainer>
  );
}

export default Login;

import { ProjectRoleServiceType } from "../services/project-role-service";
import ProjectRole from "../models/project-role";

export class ClientOpenProjectRolesInteractor {
  jwt: string;
  projectRoleService: ProjectRoleServiceType;

  constructor(jwt: string, projectRoleService: ProjectRoleServiceType) {
    this.jwt = jwt;
    this.projectRoleService = projectRoleService;
  }

  async getClientOpenProjectRoles(clientId: number): Promise<ProjectRole[]> {
    return this.projectRoleService.getClientOpenProjectRoles(
      this.jwt,
      clientId
    );
  }
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import theme from "../styles/theme";
import Link from "next/link";
import ProjectRoleRow from "../components/project-role-row";
import ProjectRole from "../../domain/models/project-role";
import Header from "./header";
import Loader from './loader'
import Client from "../../domain/models/client";

const MainContainer = styled.div`
  padding: 1rem;
  width: 75%;
`;
const Heading = styled.div`
  display: flex;
  margin-bottom: .5rem;
`
const TitleCol = styled.div`
  width: 40%;
  padding-left: .5rem;
  color: ${theme.colors.text};
  font-size: 14px;
  font-weight: 300;
`
const BuyerCol = styled.div`
  width: 35%;
  color: ${theme.colors.text};
  font-size: 14px;
  font-weight: 300;
`
const StatsCol = styled.div`
  width: 25%;
  text-align: right;
  padding-right: .75rem;
  color: ${theme.colors.text};
  font-size: 14px;
  font-weight: 300;
`
const EmptyContainer = styled.div`
  padding: 0.75rem;
`;

type PropsType = {
  clientId: number
  clientOpenProjectRoles: ProjectRole[]
}

function ClientOpenRoles(props: PropsType) {
  const { clientId, clientOpenProjectRoles } = props

  const renderDetails = () => {
    if (clientId && clientOpenProjectRoles) {
      if (clientOpenProjectRoles.length > 0) {
        return clientOpenProjectRoles.map((p: ProjectRole) => (
          <ProjectRoleRow
            key={p.id}
            projectRole={p}
            clientId={clientId}
          />
        ))
      }
      return <EmptyContainer><p>No roles currently open.</p></EmptyContainer>
    }
    return <Loader />
  }

  return (
    <>
      <Header title="Open Roles" />
      <MainContainer>
        <Heading>
          <TitleCol>ROLE</TitleCol>
          <BuyerCol>PROJECT</BuyerCol>
          <StatsCol>INFO</StatsCol>
        </Heading>
        {renderDetails()}
      </MainContainer>
    </>
  );
}

export default ClientOpenRoles;

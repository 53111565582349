import React, { useEffect, useState } from 'react';
import { GetServerSideProps } from 'next';
import { getSession, propsSessionAuth, propsSessionClient } from '../utils/session';
import Dashboard from '../presentation/components/dashboard';
import ClientOpenRoles from '../presentation/components/client-open-roles';
import Login from '../presentation/components/login';
import { useDispatch, useSelector } from 'react-redux';
import {
  configSelector,
  fetchConfigAction,
  fetchAllProjectsAction,
  projectRolesWithPendingSubmissionsSelector,
  openProjectsSelector
} from '../adapters/redux';
import { DashboardInteractor } from '../domain/usecases/dashboard-interactor';
import { ConfigService } from '../data/services/data-config-service';
import { ActivityService } from '../data/services/data-activity-service';
import { logError } from '../utils/log';
import { ClientOpenProjectRolesInteractor } from '../domain/usecases/client-open-project-roles-interactor';
import { ProjectRoleService } from '../data/services/data-project-role-service';

function IndexPage(props) {
  const { session } = props;
  // console.log("IndexPage session", session);
  const dispatch = useDispatch();
  const config = useSelector(configSelector);
  const projectRolesWithPendingSubmissions = useSelector(
    projectRolesWithPendingSubmissionsSelector
  );
  const openProjects = useSelector(openProjectsSelector);
  const [projectsDueSoon, setProjectsDueSoon] = useState(null);
  const [projectsRecent, setProjectsRecent] = useState(null);
  const [projectRolesRecent, setProjectRolesRecent] = useState(null);
  const [submissionsRecent, setSubmissionsRecent] = useState(null);
  const [activities, setActivities] = useState(null);
  const [clientOpenProjectRoles, setClientOpenProjectRoles] = useState(null);

  useEffect(() => {
    dispatch(fetchConfigAction());
    if (session) {
      if (session.user?.role == 'client') {
        fetchClientData();
      } else {
        fetchAuthorizedData();
      }
    }
  }, []);

  const fetchClientData = () => {
    const clientId = session?.client_id;
    const clientOpenProjectRolesInteractor = new ClientOpenProjectRolesInteractor(
      session?.jwt,
      new ProjectRoleService()
    );
    clientOpenProjectRolesInteractor
      .getClientOpenProjectRoles(clientId)
      .then((projectRoles) => {
        setClientOpenProjectRoles(projectRoles);
      })
      .catch((err) => {
        dispatch(logError(err, 'Open Roles for client'));
      });
  };

  const fetchAuthorizedData = () => {
    dispatch(fetchAllProjectsAction(session?.jwt));
    const dashboardInteractor = new DashboardInteractor(
      session?.jwt,
      new ConfigService(),
      new ActivityService()
    );
    dashboardInteractor
      .getDashboard()
      .then((dashboard) => {
        setProjectsDueSoon(dashboard.projects_due_soon);
        setProjectsRecent(dashboard.project_recent);
        setProjectRolesRecent(dashboard.project_roles_recent);
        setSubmissionsRecent(dashboard.submissions_recent);
      })
      .catch((err) => {
        dispatch(logError(err, 'Dashboard'));
      });
    dashboardInteractor
      .getLatestActivity()
      .then((activities) => {
        setActivities(activities);
      })
      .catch((err) => {
        dispatch(logError(err, 'Dashboard'));
      });
  };

  if (session) {
    if (session.user?.role == 'client') {
      return (
        <ClientOpenRoles
          clientId={session?.client_id}
          clientOpenProjectRoles={clientOpenProjectRoles}
        />
      );
    } else {
      return (
        <Dashboard
          projectsDueSoon={projectsDueSoon}
          projectsRecent={projectsRecent}
          projectRolesRecent={projectRolesRecent}
          submissionsRecent={submissionsRecent}
          projectRolesWithPendingSubmissions={
            projectRolesWithPendingSubmissions
              ? projectRolesWithPendingSubmissions.slice(0, 10)
              : []
          }
          openProjects={openProjects ? openProjects.slice(0, 10) : []}
          activities={activities}
        />
      );
    }
  }

  return <Login config={config} />;
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const session = await getSession(ctx);
  if (session) {
    if (session.user?.role == 'client') {
      return await propsSessionClient(ctx, { title: 'Open Roles', layout: 'client' });
    } else {
      return await propsSessionAuth(ctx, { title: 'Dashboard', layout: 'authenticated' });
    }
  } else {
    return {
      props: {
        layout: 'anonymous'
      }
    };
  }
};

export default IndexPage;

import React from "react";
import styled from "styled-components";
import media from "styled-media-query";
import theme from "../styles/theme";
import Link from "next/link";

const Container = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    padding-left: 2rem;
    padding-right: 2rem;
    color: #414141;
    font-size: 23px;
    font-weight: 300;
    @media (max-width: 890px) {
      font-size: 17px;
    }
  }
`;
const Actions = styled.div`
  a {
    background-color: ${theme.colors.primary};
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: ${theme.colors.primaryText};
    border-radius: 0.2rem;
    margin-left: 0.5rem;
  }
  .secondary {
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.secondaryText};
  }
`;

function Header(props) {
  const { children, title, actions } = props;

  const renderActions = () => {
    if (!actions) {
      return null;
    }

    return (
      <Actions>
        {children}
        {actions.map((a, i) => (
          <Link key={i} href={a.href}>
            <a className={a.secondary ? "secondary" : "primary"}>{a.title}</a>
          </Link>
        ))}
      </Actions>
    );
  };

  return (
    <Container>
      <div>{title}</div>
      {renderActions()}
    </Container>
  );
}

export default Header;
import React from "react";
import styled from "styled-components";
import theme from "../styles/theme";
import LoaderSpinner from "react-loader-spinner";

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

function Loader() {
  return (
    <Container>
      <LoaderSpinner
        type="ThreeDots"
        color={theme.colors.secondary}
        height={10}
        width={20}
        timeout={4000}
      />
    </Container>
  )
}

export default Loader;

import { ConfigServiceType } from "../services/config-service";
import { ActivityServiceType } from "../services/activity-service";
import Dashboard from "../models/dashboard";
import Activity from "../models/activity";

export class DashboardInteractor {
  jwt: string;
  configService: ConfigServiceType;
  activityService: ActivityServiceType;

  constructor(
    jwt: string,
    configService: ConfigServiceType,
    activityService: ActivityServiceType
  ) {
    this.jwt = jwt;
    this.configService = configService;
    this.activityService = activityService;
  }

  async getDashboard(): Promise<Dashboard> {
    return this.configService.getDashboard(this.jwt);
  }

  async getLatestActivity(): Promise<Activity[]> {
    return this.activityService.getLatest(this.jwt);
  }
}

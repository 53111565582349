import { ActivityServiceType } from "../../domain/services/activity-service";
import fetch from "isomorphic-unfetch";
import Activity from "../../domain/models/activity";
import { parseJsonRes } from "../../utils/parse-res";

export class ActivityService implements ActivityServiceType {
  async getLatest(jwt: string): Promise<Activity[]> {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/activities/latest`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );
    const resData = await parseJsonRes(res);
    const activities = resData.map((o) => new Activity().fromJson(o));
    return activities;
  }
}

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import Link from 'next/link';
import Project from '../../domain/models/project';
import ProjectRole from '../../domain/models/project-role';
import Submission from '../../domain/models/submission';
import Moment from 'react-moment';
import Activity from '../../domain/models/activity';
import Header from './header';
import Loader from './loader';

const Container = styled.div`
  padding: 1rem;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr;
    grid-template-areas:
      'projectsDueSoon projectRolesRecent activities'
      'projectsRecent submissionsRecent activities'
      'openProjects projectRolesWithPendingSubmissions activities';
  }
`;
const TileContainer = styled.div`
  /* width: 300px; */
  background-color: ${theme.colors.background};
  border-radius: 3px;
  margin-top: 1rem;
  margin-right: 1rem;
  padding: 1rem;
  h4 {
    color: ${theme.colors.text};
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  ul {
    padding: 0;
    margin: 0;
    padding-left: 1rem;
  }
  li {
    /* list-style: none; */
    margin-top: 0.2rem;
    margin-bottom: 0.75rem;
    color: ${theme.colors.text};
    font-size: 13px;
    font-weight: 300;
    span {
      padding-left: 0.5rem;
    }
    .date-wrapper {
      padding-left: 0;
    }
  }
`;
const ActivityContainer = styled.div`
  /* width: 300px; */
  background-color: ${theme.colors.background};
  border-radius: 3px;
  margin-top: 1rem;
  margin-right: 1rem;
  padding: 1rem;
  h4 {
    color: ${theme.colors.text};
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  h6 {
    color: ${theme.colors.text};
    font-size: 11px;
    font-weight: 300;
    margin: 0;
    margin-top: 0.3rem;
  }
  ul {
    padding: 0;
    margin: 0;
    padding-left: 1rem;
  }
  li {
    /* list-style: none; */
    margin-top: 0.2rem;
    margin-bottom: 0.75rem;
    color: ${theme.colors.text};
    font-size: 13px;
    font-weight: 300;
    span {
      padding-right: 0.3rem;
    }
    a {
      font-size: 13px;
      font-weight: 400;
      text-decoration: underline;
    }
  }
`;
const ActivityTitle = styled.span`
  font-size: 13px;
  font-weight: 400;
`;
const ActivityAction = styled.span`
  font-size: 13px;
  font-weight: 200;
`;
const EmptyContainer = styled.div``;

type PropsType = {
  projectsDueSoon: Project[];
  projectsRecent: Project[];
  projectRolesRecent: ProjectRole[];
  submissionsRecent: Submission[];
  projectRolesWithPendingSubmissions: ProjectRole[];
  openProjects: Project[];
  activities: Activity[];
};

function Dashboard(props: PropsType) {
  const {
    projectsDueSoon,
    projectsRecent,
    projectRolesRecent,
    submissionsRecent,
    projectRolesWithPendingSubmissions,
    openProjects,
    activities
  } = props;

  const linkForActivity = (activity: Activity) => {
    if (activity.subject_type == 'buyer') {
      return `/buyers/${activity.subject_id}`;
    }
    if (activity.subject_type == 'client') {
      return `/clients/${activity.subject_id}`;
    }
    if (activity.subject_type == 'project_role') {
      return `/project-roles/${activity.subject_id}`;
    }
    if (activity.subject_type == 'project') {
      return `/projects/${activity.subject_id}`;
    }
    return '/';
  };

  const daysDiff = (dateString: string) => {
    const dte = new Date(dateString);
    const now = new Date();
    const diffTime = dte.getTime() - now.getTime();
    const diffDays = diffTime / (1000 * 3600 * 24);
    return parseInt(diffDays.toFixed(0));
  };

  const styleForDateDiff = (dateString: string) => {
    if (dateString && dateString.length > 0) {
      const days = daysDiff(dateString);
      if (days <= 0) {
        return { color: 'red' };
      }
      if (days == 2) {
        return { color: 'orange' };
      }
    }
    return {};
  };

  const renderProjectsDueSoon = () => {
    if (projectsDueSoon) {
      if (projectsDueSoon.length > 0) {
        return (
          <ul>
            {projectsDueSoon.map((p) => (
              <li key={p.id}>
                <Link href={`/projects/${p.id}`}>
                  <a>
                    <span className="date-wrapper" style={styleForDateDiff(p.due.toString())}>
                      <Moment format="DD MMM">{p.due}</Moment>
                    </span>
                    <span>{p.title}</span>
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        );
      }
      return (
        <EmptyContainer>
          <p>No records.</p>
        </EmptyContainer>
      );
    }
    return <Loader />;
  };

  const renderProjectRolesRecent = () => {
    if (projectRolesRecent) {
      if (projectRolesRecent.length > 0) {
        return (
          <ul>
            {projectRolesRecent.map((r) => (
              <li key={r.id}>
                <Link href={`/project-roles/${r.id}`}>
                  <a>
                    <Moment format="DD MMM">{r.created_at}</Moment>
                    <span>
                      {r?.title} - {r.project?.title}
                    </span>
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        );
      }
      return (
        <EmptyContainer>
          <p>No records.</p>
        </EmptyContainer>
      );
    }
    return <Loader />;
  };

  const renderProjectsRecent = () => {
    if (projectsRecent) {
      if (projectsRecent.length > 0) {
        return (
          <ul>
            {projectsRecent.map((p) => (
              <li key={p.id}>
                <Link href={`/projects/${p.id}`}>
                  <a>
                    <Moment format="DD MMM">{p.created_at}</Moment>
                    <span>{p.title}</span>
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        );
      }
      return (
        <EmptyContainer>
          <p>No records.</p>
        </EmptyContainer>
      );
    }
    return <Loader />;
  };

  const renderSubmissionsRecent = () => {
    if (submissionsRecent) {
      if (submissionsRecent.length > 0) {
        return (
          <ul>
            {submissionsRecent.map((s) => (
              <li key={s.id}>
                <Link href={`/project-roles/${s.project_role.id}`}>
                  <a>
                    <Moment format="DD MMM">{s.created_at}</Moment>
                    <span>
                      {s.client?.name || 'Unnamed'} - {s.project_role.title}
                    </span>
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        );
      }
      return (
        <EmptyContainer>
          <p>No records.</p>
        </EmptyContainer>
      );
    }
    return <Loader />;
  };

  const renderOpenProjects = () => {
    if (openProjects) {
      if (openProjects.length > 0) {
        return (
          <ul>
            {openProjects.map((p) => (
              <li key={p.id}>
                <Link href={`/projects/${p.id}`}>
                  <a>
                    <Moment format="DD MMM">{p.created_at}</Moment>
                    <span>{p.title}</span>
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        );
      }
      return (
        <EmptyContainer>
          <p>No records.</p>
        </EmptyContainer>
      );
    }
    return <Loader />;
  };

  const renderProjectRolesWithPendingSubmissions = () => {
    if (renderProjectRolesWithPendingSubmissions) {
      if (renderProjectRolesWithPendingSubmissions.length > 0) {
        return (
          <ul>
            {projectRolesWithPendingSubmissions.map((r) => (
              <li key={r.id}>
                <Link href={`/project-roles/${r.id}`}>
                  <a>
                    <Moment format="DD MMM">{r.created_at}</Moment>
                    <span>
                      {r.title} - {r.project.title}
                    </span>
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        );
      }
      return (
        <EmptyContainer>
          <p>No records.</p>
        </EmptyContainer>
      );
    }
    return <Loader />;
  };

  const renderActivities = () => {
    if (activities) {
      if (activities.length > 0) {
        return (
          <ul>
            {activities.map((a) => (
              <li key={a.id}>
                <div>
                  <ActivityTitle>{a.title}</ActivityTitle>
                  <ActivityAction>{a.action}</ActivityAction>
                  <Link href={linkForActivity(a)}>
                    <a>
                      <span>{a.subject_title}</span>
                    </a>
                  </Link>
                </div>
                <h6>
                  <Moment fromNow>{a.created_at}</Moment>
                </h6>
              </li>
            ))}
          </ul>
        );
      }
      return (
        <EmptyContainer>
          <p>No records.</p>
        </EmptyContainer>
      );
    }
    return <Loader />;
  };

  return (
    <>
      <Header title="Dashboard" />
      <Container>
        <TileContainer style={{ gridArea: 'projectsDueSoon' }}>
          <h4>Due Soon</h4>
          {renderProjectsDueSoon()}
        </TileContainer>
        <TileContainer style={{ gridArea: 'projectRolesRecent' }}>
          <h4>Recent Roles</h4>
          {renderProjectRolesRecent()}
        </TileContainer>
        <TileContainer style={{ gridArea: 'projectsRecent' }}>
          <h4>Recent Projects</h4>
          {renderProjectsRecent()}
        </TileContainer>
        <TileContainer style={{ gridArea: 'submissionsRecent' }}>
          <h4>Recent Submissions</h4>
          {renderSubmissionsRecent()}
        </TileContainer>
        <TileContainer style={{ gridArea: 'openProjects' }}>
          <h4>Open Projects</h4>
          {renderOpenProjects()}
        </TileContainer>
        <TileContainer style={{ gridArea: 'projectRolesWithPendingSubmissions' }}>
          <h4>Roles With Pending Submissions</h4>
          {renderProjectRolesWithPendingSubmissions()}
        </TileContainer>
        <ActivityContainer style={{ gridArea: 'activities' }}>
          <h4>Activity</h4>
          {renderActivities()}
        </ActivityContainer>
      </Container>
    </>
  );
}

export default Dashboard;

class Activity {
  id: number;
  title: string;
  created_at: Date;
  updated_at: Date;
  action: string;
  subject_title: string;
  subject_id: number;
  subject_type: string;

  fromJson(data): Activity {
    this.id = data.id;
    this.title = data.title;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
    this.action = data.action;
    this.subject_title = data.subject_title;
    this.subject_id = data.subject_id;
    this.subject_type = data.subject_type;
    return this;
  }
}

export default Activity;
